import {
  AccountTemplate,
  PointRedemptionApplyContents,
  PointRedemptionCompleteContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { useReducer, useState, type FC, FormEventHandler } from 'react';

import { pointExchange } from '../../api/client/fetch/point';
import { AppHeader } from '../../components/AppHeader';
import { appBaseUrl, appUrl, portalUrl } from '../../constants/url';
import { exchangeablePointThreshold } from '../../domain/point';

import type { MetaTag } from '../../constants/metaTag';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'Myページ',
    url: appBaseUrl(),
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: 'ポイント交換申請',
    url: appUrl.pointExchangeRequest,
  } as const,
];

const isLoggedIn = true;

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
  isExchangeablePoint?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const PointExchangeRequestTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
  isExchangeablePoint,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [exchangeRequested, updateExchangeRequested] = useReducer(
    () => true,
    false,
  );

  const [exchangeRequestedLoggedInUser, setExchangeRequestedLoggedInUser] =
    useState<LoggedInUser>(loggedInUser);

  const [errorMessage, setErrorMessage] = useState<string>();

  // eslint-disable-next-line require-await
  const submit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await pointExchange();

      // 申請後のポイントの表示を変える為の処置
      const requestedLoggedInUser = {
        ...loggedInUser,
        ...{
          pointBalance: loggedInUser.pointBalance - exchangeablePointThreshold,
        },
      };

      setExchangeRequestedLoggedInUser(requestedLoggedInUser);

      updateExchangeRequested();
    } catch (error) {
      setErrorMessage(
        '予期せぬエラーが発生しました。お手数ですがしばらく時間が経ってからお試し下さい。',
      );
    } finally {
      setLoading(false);
    }
  };

  const eventHandlers = {
    submit,
  };

  if (isExchangeablePoint !== true) {
    return (
      <>
        <AppHeader metaTag={metaTag} noIndex={false} />
        <AccountTemplate
          pageHeader={{ title: 'ポイント交換申請' }}
          appEnv={appEnv}
          breadcrumbElements={breadcrumbElements}
          loggedInUser={loggedInUser}
          isLoggedIn={isLoggedIn}
          loading={false}
        >
          <PointRedemptionApplyContents
            appEnv={appEnv}
            loggedInUser={loggedInUser}
            eventHandlers={eventHandlers}
            disablePointExchangeButton={true}
            errorMessage={`ポイント交換には最低${exchangeablePointThreshold}ポイント必要です。`}
          />
        </AccountTemplate>
      </>
    );
  }

  if (exchangeRequested) {
    return (
      <>
        <AppHeader metaTag={metaTag} noIndex={false} />
        <AccountTemplate
          pageHeader={{ title: 'ポイント交換申請' }}
          appEnv={appEnv}
          breadcrumbElements={breadcrumbElements}
          loggedInUser={exchangeRequestedLoggedInUser}
          isLoggedIn={isLoggedIn}
          loading={loading}
        >
          <PointRedemptionCompleteContents
            appEnv={appEnv}
            loggedInUser={exchangeRequestedLoggedInUser}
          />
        </AccountTemplate>
      </>
    );
  }

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountTemplate
        pageHeader={{ title: 'ポイント交換申請' }}
        appEnv={appEnv}
        breadcrumbElements={breadcrumbElements}
        loggedInUser={loggedInUser}
        isLoggedIn={isLoggedIn}
        loading={loading}
      >
        <PointRedemptionApplyContents
          appEnv={appEnv}
          loggedInUser={loggedInUser}
          eventHandlers={eventHandlers}
          disablePointExchangeButton={false}
          errorMessage={errorMessage}
        />
      </AccountTemplate>
    </>
  );
};
