import {
  nurseSenkaAllUrl,
  portalUrl as getPortalUrl,
  insuranceUrl as getInsuranceUrl,
  defaultProfileImageUrl,
  type BffUrl,
  AccountUrl,
  PortalUrl,
  InsuranceUrl,
} from '@nurse-senka/nurse-senka-frontend-sdk';

import { PostalCode } from '../domain/address';

import { appEnv } from './env';

import type { AccessTokenValue } from '../domain/auth';
import type { EmailVerifyToken } from '../domain/changeEmail';
import type { PictureUrl, Sub } from '../domain/user';

export const appBaseUrl = (): AccountUrl =>
  process.env.NEXT_PUBLIC_APP_URL
    ? (process.env.NEXT_PUBLIC_APP_URL as AccountUrl)
    : 'https://account.nurse-senka.jp';

export const apiGatewayBaseUrl = (): string =>
  nurseSenkaAllUrl(appEnv()).apiGateway;

export const knowledgeApiBaseUrl = (): string =>
  process.env.KNOWLEDGE_API_BASE_URL ? process.env.KNOWLEDGE_API_BASE_URL : '';

export const authleteApiBaseUrl = (): string =>
  process.env.AUTHLETE_API_BASE_URL ? process.env.AUTHLETE_API_BASE_URL : '';

export const bffBaseUrl = (): BffUrl => nurseSenkaAllUrl(appEnv()).bff;

export const portalUrl = (): PortalUrl => getPortalUrl(appEnv());

const insuranceUrl = (): InsuranceUrl => getInsuranceUrl(appEnv());

export const apiPath = {
  registrations: '/api/registrations',
  login: '/api/login',
  authorization: '/api/auth/authorization',
  accountsCancel: '/api/accounts/cancel',
  passwordResetApply: '/api/accounts/password/reset/apply',
  passwordResetComplete: '/api/accounts/password/reset/complete',
  initialSetting: '/api/settings/init',
  newsNotification: '/api/notifications/news',
  jobsNotification: '/api/notifications/jobs',
  emailChangeApply: '/api/accounts/email/apply',
  fetchSchoolNames: '/api/schools',
  pointExchange: '/api/points/exchanges',
  changeProfile: '/api/users/profiles',
  changePreferredUsername: '/api/users/profiles/preferred-usernames',
  changeName: '/api/users/profiles/names',
  changePhoneNumber: '/api/users/profiles/phone-numbers',
  changeGender: '/api/users/profiles/genders',
  changeFamilyStructure: '/api/users/profiles/family-structures',
  changeBirthdate: '/api/users/profiles/birthdates',
  changeAddress: '/api/users/profiles/addresses',
  users: '/api/users/authenticated',
  fetchAddressByPostalCode: '/api/addresses',
  changeEmployment: '/api/users/profiles/employments',
  changeActivityArea: '/api/users/profiles/activity-areas',
  changeQualifications: '/api/users/profiles/qualifications',
  changeLicense: '/api/users/profiles/licenses',
  changeAffiliation: '/api/users/profiles/affiliations',
  changeCustomLicense: '/api/users/profiles/custom-licenses',
  changeNurseLicenseNumber: '/api/users/profiles/nurse-license-numbers',
  changeSchool: '/api/users/profiles/schools',
  changeScholarship: '/api/users/profiles/scholarships',
} as const;

export const appUrlPath = {
  top: '/',
  registrations: '/registrations',
  registrationsComplete: '/registrations/complete',
  settingsInit: '/settings/init',
  login: '/login',
  cancel: '/my/cancel',
  passwordResetApply: '/passwords/reset',
  passwordResetComplete: '/passwords/',
  pointExchangeRequest: '/my/points/exchange/request',
  pointEarnHistories: '/my/points/earn-histories',
  pointExchangeHistories: '/my/points/exchange-histories',
  mailMagazineSetting: '/my/mail-magazine/settings',
  emailChangeApply: '/my/emails',
  emailChangeComplete: '/my/emails/',
  changeProfile: '/my/profiles',
  mailMagazineUnsubscribe: '/mail-magazine/unsubscribe',
  error: '/error',
} as const;

export const appUrl = {
  top: appBaseUrl(),
  registrations: `${appBaseUrl()}${appUrlPath.registrations}`,
  registrationsComplete: `${appBaseUrl()}${appUrlPath.registrationsComplete}`,
  settingsInit: `${appBaseUrl()}${appUrlPath.settingsInit}`,
  login: `${appBaseUrl()}${appUrlPath.login}`,
  cancel: `${appBaseUrl()}${appUrlPath.cancel}`,
  passwordResetApply: `${appBaseUrl()}${appUrlPath.passwordResetApply}`,
  passwordResetComplete: `${appBaseUrl()}${appUrlPath.passwordResetComplete}`,
  pointExchangeRequest: `${appBaseUrl()}${appUrlPath.pointExchangeRequest}`,
  pointEarnHistories: `${appBaseUrl()}${appUrlPath.pointEarnHistories}`,
  pointExchangeHistories: `${appBaseUrl()}${appUrlPath.pointExchangeHistories}`,
  mailMagazineSetting: `${appBaseUrl()}${appUrlPath.mailMagazineSetting}`,
  emailChangeApply: `${appBaseUrl()}${appUrlPath.emailChangeApply}`,
  emailChangeComplete: `${appBaseUrl()}${appUrlPath.emailChangeComplete}`,
  changeProfile: `${appBaseUrl()}${appUrlPath.changeProfile}`,
  mailMagazineUnsubscribe: `${appBaseUrl()}${
    appUrlPath.mailMagazineUnsubscribe
  }`,
  ogpImg: `${appBaseUrl()}/opengraph-image.webp`,
  error: `${appBaseUrl()}${appUrlPath.error}`,
} as const;

export const externalApiUrl = {
  issueAccessTokenInClientCredentials: `${bffBaseUrl()}/access-token`,
  userInfoEndpoint: `${apiGatewayBaseUrl()}/userinfo`,
  fetchOidcClient: `${apiGatewayBaseUrl()}/clients/`,
  issueAuthorizationCode: `${apiGatewayBaseUrl()}/auth/authorization/code`,
  issueAccessTokenInAuthorizationCode: `${apiGatewayBaseUrl()}/tokens/code`,
  knowledgeApiBaseUrl: knowledgeApiBaseUrl(),
  issueAuthorizationTicket: `${authleteApiBaseUrl()}/api/auth/authorization`,
  issueAuthorizationCodeFromTicket: `${authleteApiBaseUrl()}/api/auth/authorization/issue`,
  fetchAuthenticatedUser: `${bffBaseUrl()}/users/authenticated`,
  cancelAccount: `${apiGatewayBaseUrl()}/account/v1/accounts/`,
  sendCancelCompleteMessage: `${apiGatewayBaseUrl()}/mail-send/account/v1/cancel/complete`,
  passwordResetApply: `${apiGatewayBaseUrl()}/account/v1/password-reset-tokens`,
  sendPasswordResetApplyMessage: `${apiGatewayBaseUrl()}/mail-send/account/v1/passwords/apply`,
  findPasswordResetToken: `${apiGatewayBaseUrl()}/account/v1/password-reset-tokens/`,
  passwordResetComplete: `${apiGatewayBaseUrl()}/account/v1/password-reset-tokens/`,
  fetchEmployment: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/employments`,
  createEmployment: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/employments`,
  changeEmployment: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/employments`,
  createAffiliation: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/affiliations`,
  createLicense: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/licenses`,
  logout: `${apiGatewayBaseUrl()}/account/v1/logout`,
  deleteAccessToken: (accessToken: AccessTokenValue) =>
    `${apiGatewayBaseUrl()}/tokens/${accessToken}`,
  fetchInformationList: `${apiGatewayBaseUrl()}/portal/v1/information/active`,
  newsNotification: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/accounts/${sub}/notifications/news`,
  jobsNotification: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/accounts/${sub}/notifications/jobs`,
  changeEmailApply: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/accounts/${sub}/emails`,
  changeEmailComplete: (sub: Sub, emailVerifyToken: EmailVerifyToken) =>
    `${apiGatewayBaseUrl()}/account/v1/accounts/${sub}/emails/${emailVerifyToken}`,
  sendChangeEmailApplyMessage: `${apiGatewayBaseUrl()}/mail-send/account/v1/emails/apply`,
  findEmailVerifyToken: (emailVerifyToken: EmailVerifyToken) =>
    `${apiGatewayBaseUrl()}/account/v1/email-verify-tokens/${emailVerifyToken}`,
  fetchSchoolNames: `${bffBaseUrl()}/schools`,
  fetchInsurance: `${insuranceUrl()}/profile?output=json`,
  fetchPointEarnHistories: `${bffBaseUrl()}/points/earn-histories`,
  fetchPointExchangeHistories: `${bffBaseUrl()}/points/exchange-histories`,
  pointExchange: `${bffBaseUrl()}/points/exchanges`,
  fetchPreferredUsername: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/preferred-usernames`,
  createPreferredUsername: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/preferred-usernames`,
  changePreferredUsername: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/preferred-usernames`,
  changeNames: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/names`,
  changeProfile: `${bffBaseUrl()}/users/profiles`,
  createPhoneNumber: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/phone-numbers`,
  changePhoneNumber: (sub: Sub, phoneNumberId: number) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/phone-numbers/${phoneNumberId}`,
  fetchPhoneNumbers: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/phone-numbers`,
  changeGender: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/genders`,
  familyStructure: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/family-structure`,
  changeBirthdate: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/birthdates`,
  fetchAddressByPostalCode: (postalCode: PostalCode) =>
    `${apiGatewayBaseUrl()}/address-search/v1/${postalCode}`,
  fetchAddresses: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/addresses`,
  createAddress: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/addresses`,
  changeAddress: (sub: Sub, addressId: number) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/addresses/${addressId}`,
  fetchSchools: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/schools`,
  createSchool: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/schools`,
  changeSchool: (sub: Sub, schoolId: number) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/schools/${schoolId}`,
  activityArea: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/activity-areas`,
  qualifications: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/qualifications`,
  license: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/licenses`,
  affiliation: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/affiliations`,
  customLicense: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/custom-licenses`,
  nurseLicenseNumber: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/license-numbers`,
  scholarship: (sub: Sub) =>
    `${apiGatewayBaseUrl()}/account/v1/users/${sub}/scholarships`,
  secretKey: (secretKey: string) =>
    `${apiGatewayBaseUrl()}/account/v1/secret-keys/${secretKey}`,
} as const;

export const unknownPictureUrl = (): PictureUrl => {
  const env = appEnv();

  return defaultProfileImageUrl(env);
};
