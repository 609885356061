import {
  AccountTemplate,
  PointExchangeHistoryContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { format as dateFormat } from 'date-fns';

import { AppHeader } from '../../components/AppHeader';
import { appBaseUrl, portalUrl, appUrl } from '../../constants/url';
import { FetchPointExchangeHistoriesResponse } from '../../domain/point';

import type { MetaTag } from '../../constants/metaTag';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { FC } from 'react';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'Myページ',
    url: appBaseUrl(),
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: 'ポイント交換履歴',
    url: appUrl.pointExchangeHistories,
  } as const,
];

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
  pointExchangeHistories?: FetchPointExchangeHistoriesResponse;
};

const pageHeader = { title: 'ポイント交換履歴' };

const loading = false;

const isLoggedIn = true;

export const PointExchangeHistoriesTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
  pointExchangeHistories,
}) => {
  if (pointExchangeHistories) {
    const pointExchangeHistoryElements = pointExchangeHistories.map((value) => {
      if (value.expiredAt) {
        return {
          id: value.id,
          exchangedAt: dateFormat(
            new Date(value.exchangedAt),
            'yyyy/MM/dd H:mm',
          ),
          expiredAt: dateFormat(new Date(value.expiredAt), 'yyyy/MM/dd H:mm'),
          amazonGiftId: value.giftCode,
        };
      }

      return {
        id: value.id,
        exchangedAt: dateFormat(new Date(value.exchangedAt), 'yyyy/MM/dd H:mm'),
        amazonGiftId: value.giftCode,
      };
    });

    return (
      <>
        <AppHeader metaTag={metaTag} noIndex={false} />
        <AccountTemplate
          pageHeader={pageHeader}
          appEnv={appEnv}
          breadcrumbElements={breadcrumbElements}
          loggedInUser={loggedInUser}
          loading={loading}
          isLoggedIn={isLoggedIn}
        >
          <PointExchangeHistoryContents
            pointExchangeHistoryElements={pointExchangeHistoryElements}
          />
        </AccountTemplate>
      </>
    );
  }

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountTemplate
        pageHeader={pageHeader}
        appEnv={appEnv}
        breadcrumbElements={breadcrumbElements}
        loggedInUser={loggedInUser}
        loading={loading}
        isLoggedIn={isLoggedIn}
      >
        <PointExchangeHistoryContents />
      </AccountTemplate>
    </>
  );
};
