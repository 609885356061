export { IndexTemplate, type TodayFortuneTellingResult } from './IndexTemplate';
export { LoginTemplate } from './LoginTemplate';
export { RegistrationTemplate } from './RegistrationTemplate';
export { RegistrationCompleteTemplate } from './RegistrationCompleteTemplate';
export { PasswordResetApplyTemplate } from './PasswordResetApplyTemplate';
export { PasswordResetCompleteTemplate } from './PasswordResetCompleteTemplate';
export { ErrorTemplate } from './ErrorTemplate';
export { MailMagazineSettingsTemplate } from './MailMagazineSettingsTemplate';
export { EmailChangeApplyTemplate } from './EmailChangeApplyTemplate';
export { ChangeEmailCompleteTemplate } from './ChangeEmailCompleteTemplate';
export { PointEarnHistoriesTemplate } from './PointEarnHistoriesTemplate';
export { PointExchangeHistoriesTemplate } from './PointExchangeHistoriesTemplate';
export { PointExchangeRequestTemplate } from './PointExchangeRequestTemplate';
export { ChangeProfileTemplate } from './ChangeProfileTemplate';
export { MailMagazineUnsubscribeTemplate } from './MailMagazineUnsubscribeTemplate';
