import {
  AccountTemplate,
  PointEarningHistoryContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { format as dateFormat } from 'date-fns';

import { AppHeader } from '../../components/AppHeader';
import { appBaseUrl, portalUrl, appUrl } from '../../constants/url';

import type { MetaTag } from '../../constants/metaTag';
import type { FetchPointEarnHistoriesResponse } from '../../domain/point';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { FC } from 'react';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'Myページ',
    url: appBaseUrl(),
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: 'ポイント獲得履歴',
    url: appUrl.pointEarnHistories,
  } as const,
];

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
  pointEarnHistories?: FetchPointEarnHistoriesResponse;
};

const pageHeader = { title: 'ポイント獲得履歴' };

const loading = false;

const isLoggedIn = true;

export const PointEarnHistoriesTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
  pointEarnHistories,
}) => {
  if (pointEarnHistories) {
    const pointEarningHistoryElements = pointEarnHistories.map((value) => ({
      id: value.id,
      // eslint-disable-next-line no-magic-numbers
      point: value.point >= 0 ? `+ ${value.point}` : `- ${value.point}`,
      reason: value.reason,
      earnedAt: dateFormat(new Date(value.earnedAt), 'yyyy/MM/dd H:mm'),
    }));

    return (
      <>
        <AppHeader metaTag={metaTag} noIndex={false} />
        <AccountTemplate
          pageHeader={pageHeader}
          appEnv={appEnv}
          breadcrumbElements={breadcrumbElements}
          loggedInUser={loggedInUser}
          loading={loading}
          isLoggedIn={isLoggedIn}
        >
          <PointEarningHistoryContents
            pointEarningHistoryElements={pointEarningHistoryElements}
          />
        </AccountTemplate>
      </>
    );
  }

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountTemplate
        pageHeader={pageHeader}
        appEnv={appEnv}
        breadcrumbElements={breadcrumbElements}
        loggedInUser={loggedInUser}
        loading={loading}
        isLoggedIn={isLoggedIn}
      >
        <PointEarningHistoryContents />
      </AccountTemplate>
    </>
  );
};
