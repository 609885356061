import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { PointExchangeError } from '../../../domain/errors/PointExchangeError';
import { PointExchange } from '../../../domain/point';

export const pointExchange: PointExchange = async () => {
  const options = {
    method: 'POST',
  };

  const response = await fetch(apiPath.pointExchange, options);

  if (response.status !== httpStatusCode.noContent) {
    throw new PointExchangeError();
  }
};
