import { PointEarnHistoriesNotFoundError } from './errors/PointEarnHistoriesNotFoundError';
import { PointExchangeHistoriesNotFoundError } from './errors/PointExchangeHistoriesNotFoundError';

import type { LoginSessionToken } from './login';
import type { Result } from './result';
import type { Sub } from './user';

export type Point = number;

export type PointEarnedReason = string;

export type PointEarnedAt = string;

export type ExchangedGiftCode = string;

export type PointExchangedAt = string;

export type PointExpiredAt = string;

type FetchPointEarnHistoriesRequest = {
  loginSessionToken: LoginSessionToken;
  sub: Sub;
};

export type FetchPointEarnHistoriesResponse = {
  id: number;
  point: Point;
  reason: PointEarnedReason;
  earnedAt: PointEarnedAt;
}[];

export type FetchPointEarnHistories = (
  request: FetchPointEarnHistoriesRequest,
) => Promise<
  Result<FetchPointEarnHistoriesResponse, PointEarnHistoriesNotFoundError>
>;

type FetchPointExchangeHistoriesRequest = {
  loginSessionToken: LoginSessionToken;
  sub: Sub;
};

export type FetchPointExchangeHistoriesResponse = {
  id: number;
  giftCode: ExchangedGiftCode;
  exchangedAt: PointExchangedAt;
  expiredAt?: PointExpiredAt;
}[];

export type FetchPointExchangeHistories = (
  request: FetchPointExchangeHistoriesRequest,
) => Promise<
  Result<
    FetchPointExchangeHistoriesResponse,
    PointExchangeHistoriesNotFoundError
  >
>;

// ポイント交換には最低でも以下の数値以上のポイントを保持している必要がある
export const exchangeablePointThreshold = 500;

export const isExchangeablePoint = (balance: number): boolean =>
  balance >= exchangeablePointThreshold;

export type PointExchange = () => Promise<void>;
